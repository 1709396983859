import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { categoriesData } from "../../helpers/Arrays";
import { PulseLoader } from "react-spinners";
import { Images } from "../../helpers/Images";

const LeaderBoardTemplate = ({ categoriesList, categoriesLoading }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        id={"categories"}
        sx={{
          width: "100%",
          maxWidth: "1500px",
          margin: "auto",
          padding: { xs: "30px 20px", sm: "50px 60px", lg: "50px 120px" },
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px", sm: "55px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Welcome to the Leaderboard!
        </Typography>
        {/* <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "200px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto 15px auto",
          }}
        /> */}
        <Typography
          variant={"p"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "15px", sm: "20px" },
            fontWeight: "500",
            color: "primary.darker",
            display: "block",
            marginTop: "10px",
          }}
        >
          Here you can find the latest standings of all teams in our
          competition. Check back regularly to see who’s leading and how
          everyone is progressing. Congratulations to all for their hard work
          and achievements!
        </Typography>

        {categoriesList && !categoriesLoading ? (
          <>
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: { xs: "10px", md: "10px" },
                justifyContent: "center",
              }}
            >
              {categoriesList?.map((item) => (
                <Grid item xs={12} sm={6} xl={6}>
                  <Box
                    sx={{
                      backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${
                        item.name == "KidCoder"
                          ? Images.code1
                          : item.name == "Web Challenge"
                          ? Images.code2
                          : item.name == "Python Challenge"
                          ? Images.code3
                          : Images.code4
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      width: "100%",
                      minHeight: { xs: "200px", sm: "300px" },
                      borderRadius: "10px",
                      padding: { xs: "20px 10px", md: "20px 40px" },
                    }}
                  >
                    <Typography
                      variant={"h3"}
                      sx={{
                        textAlign: "center",
                        fontSize: { xs: "28px", md: "30px" },
                        fontWeight: "500",
                        color: "secondary.main",
                      }}
                    >
                      {item.name}
                    </Typography>
                    <Box
                      sx={{
                        height: "3px",
                        maxWidth: "90px",
                        width: "100%",
                        backgroundColor: "secondary.main",
                        margin: "5px auto 5px auto",
                      }}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {item?.ageGroups?.map((group) => (
                        <Button
                          variant="contained"
                          color="secondary"
                          sx={{
                            fontSize: { xs: "11px", md: "15px" },
                            fontWeight: "600",
                            color: "primary.main",
                            width: "fit-content",
                            margin: { xs: "5px 5px 0", sm: "10px 5px 0" },
                          }}
                          onClick={() => navigate(`/leaderboard/${group.id}`)}
                        >
                          {group?.name}
                        </Button>
                      ))}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <div className="loadingBox">
            <PulseLoader size={12} color="#6a0505" />
          </div>
        )}
      </Box>
    </>
  );
};

export default LeaderBoardTemplate;
