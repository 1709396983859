import React, { useState } from "react";
import "./Number.css";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import { Images } from "../../../../helpers/Images";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

function Number() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <Box
      sx={{
        width: "100%",
        padding: {
          xs: "50px 20px",
          sm: "50px 60px",
          lg: "70px 120px",
        },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "#fff",
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      <Typography
        variant={"h2"}
        sx={{
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "500",
          color: "primary.darker",
        }}
      >
        Code Challenge 2023 Numbers
      </Typography>
      <Box
        sx={{
          height: "3px",
          width: "150px",
          backgroundColor: "primary.light",
          margin: "10px auto",
        }}
      />
      <div className="counter">
        <ScrollTrigger
          onEnter={() => setCounterOn(true)}
          onExit={() => setCounterOn(false)}
        >
          <Grid container justifyContent={"center"}>
            {[
              { image: Images.team, number: "120", title: "Teams" },
              { image: Images.govern, number: "19", title: "Governorates" },
              {
                image: Images.academy,
                number: "50",
                title: "Academies/Schools",
              },
              { image: Images.coach, number: "80", title: "Coaches" },
              { image: Images.student, number: "205", title: "Students" },
              { image: Images.category, number: "5", title: "Categories" },
            ].map((item, i) => (
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={4}
                key={i}
                justifyContent={"center"}
              >
                <div className="CounterItem">
                  <div className="outer">
                    <div className="inner">
                      <img
                        alt=""
                        src={item.image}
                        style={{
                          height: "70px",
                          filter:
                            "invert(13%) sepia(48%) saturate(5796%) hue-rotate(350deg) brightness(103%) contrast(93%)",
                        }}
                      />
                      <div id="number">
                        {(counterOn && (
                          <>
                            <CountUp
                              start={0}
                              end={item.number}
                              duration={3}
                              delay={1}
                            />
                            {(item?.title === "Teams" ? "+" : "") ||
                              (item?.title === "Academies/Schools"
                                ? "+"
                                : "") ||
                              (item?.title === "Coaches" ? "+" : "") ||
                              (item?.title === "Students" ? "+" : "")}
                          </>
                        )) ||
                          0}
                      </div>
                    </div>
                  </div>
                  <p className="counterText">{item.title}</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="200px"
                    height="200px"
                    className="circles"
                  >
                    <defs>
                      <linearGradient id="GradientColor">
                        <stop offset="0%" stop-color="#DA22FF" />
                        <stop offset="100%" stop-color="#9733EE" />
                      </linearGradient>
                    </defs>
                    <circle
                      style={{ animationName: counterOn ? "counter" : "" }}
                      cx="100"
                      cy="100"
                      r="95"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </Grid>
            ))}
          </Grid>
        </ScrollTrigger>
      </div>
    </Box>
  );
}

export default Number;
