import gallery1 from "../assets/gallery/image (1).jpg";
import gallery2 from "../assets/gallery/image (2).jpg";
import gallery3 from "../assets/gallery/image (3).jpg";
import gallery4 from "../assets/gallery/image (4).jpg";
import gallery5 from "../assets/gallery/image (5).jpg";
import gallery6 from "../assets/gallery/image (6).jpg";
import gallery7 from "../assets/gallery/image (7).jpg";
import gallery8 from "../assets/gallery/image (8).jpg";
import gallery9 from "../assets/gallery/image (9).jpg";
import gallery10 from "../assets/gallery/image (10).jpg";
import gallery11 from "../assets/gallery/image (11).jpg";
import gallery12 from "../assets/gallery/image (12).jpg";
import gallery13 from "../assets/gallery/image (13).jpg";
import gallery14 from "../assets/gallery/image (14).jpg";
import gallery15 from "../assets/gallery/image (15).jpg";
import gallery16 from "../assets/gallery/image (16).jpg";
import gallery17 from "../assets/gallery/image (17).jpg";
import gallery18 from "../assets/gallery/image (18).jpg";
import gallery19 from "../assets/gallery/image (19).jpg";
import gallery20 from "../assets/gallery/image (20).jpg";
import gallery21 from "../assets/gallery/image (21).jpg";
import gallery22 from "../assets/gallery/image (22).jpg";
import gallery23 from "../assets/gallery/image (23).jpg";
import gallery24 from "../assets/gallery/image (24).jpg";
import gallery25 from "../assets/gallery/image (25).jpg";
import gallery26 from "../assets/gallery/image (26).jpg";
import gallery27 from "../assets/gallery/image (27).jpg";
import gallery28 from "../assets/gallery/image (28).jpg";
import gallery29 from "../assets/gallery/image (29).jpg";
import gallery30 from "../assets/gallery/image (30).jpg";
import gallery31 from "../assets/gallery/image (31).jpg";
import gallery32 from "../assets/gallery/image (32).jpg";
import gallery33 from "../assets/gallery/image (33).jpg";
import gallery34 from "../assets/gallery/image (34).jpg";
import gallery35 from "../assets/gallery/image (35).jpg";
import gallery36 from "../assets/gallery/image (36).jpg";
import gallery37 from "../assets/gallery/image (37).jpg";
import gallery38 from "../assets/gallery/image (38).jpg";
import gallery39 from "../assets/gallery/image (39).jpg";
import gallery40 from "../assets/gallery/image (40).jpg";
import gallery41 from "../assets/gallery/image (41).jpg";
import gallery42 from "../assets/gallery/image (42).jpg";
import gallery43 from "../assets/gallery/image (43).jpg";
import gallery44 from "../assets/gallery/image (44).jpg";
import gallery45 from "../assets/gallery/image (45).jpg";
import gallery46 from "../assets/gallery/image (46).jpg";
import gallery47 from "../assets/gallery/image (47).jpg";
import gallery48 from "../assets/gallery/image (48).jpg";
import gallery49 from "../assets/gallery/image (49).jpg";
import gallery50 from "../assets/gallery/image (50).jpg";
import gallery51 from "../assets/gallery/image (51).jpg";
import gallery52 from "../assets/gallery/image (52).jpg";
import gallery53 from "../assets/gallery/image (53).jpg";
import gallery54 from "../assets/gallery/image (54).jpg";
import gallery55 from "../assets/gallery/image (55).jpg";
import gallery56 from "../assets/gallery/image (56).jpg";
import gallery57 from "../assets/gallery/image (57).jpg";
import gallery58 from "../assets/gallery/image (58).jpg";
import gallery59 from "../assets/gallery/image (59).jpg";
import gallery60 from "../assets/gallery/image (60).jpg";
import gallery61 from "../assets/gallery/image (61).jpg";
import gallery62 from "../assets/gallery/image (62).jpg";
import gallery63 from "../assets/gallery/image (63).jpg";
import gallery64 from "../assets/gallery/image (64).jpg";
import gallery65 from "../assets/gallery/image (65).jpg";
import gallery66 from "../assets/gallery/image (66).jpg";
import gallery67 from "../assets/gallery/image (67).jpg";
import gallery68 from "../assets/gallery/image (68).jpg";
import gallery69 from "../assets/gallery/image (69).jpg";
import gallery70 from "../assets/gallery/image (70).jpg";
import gallery71 from "../assets/gallery/image (71).jpg";
import gallery72 from "../assets/gallery/image (72).jpg";
import gallery73 from "../assets/gallery/image (73).jpg";
import gallery74 from "../assets/gallery/image (74).jpg";
import gallery75 from "../assets/gallery/image (75).jpg";
import gallery76 from "../assets/gallery/image (76).jpg";
import gallery77 from "../assets/gallery/image (77).jpg";
import gallery78 from "../assets/gallery/image (78).jpg";
import gallery79 from "../assets/gallery/image (79).jpg";
import gallery80 from "../assets/gallery/image (80).jpg";
import gallery81 from "../assets/gallery/image (81).jpg";
import gallery82 from "../assets/gallery/image (82).jpg";
import gallery83 from "../assets/gallery/image (83).jpg";
import gallery84 from "../assets/gallery/image (84).jpg";
import gallery85 from "../assets/gallery/image (85).jpg";
import gallery86 from "../assets/gallery/image (86).jpg";
import gallery87 from "../assets/gallery/image (87).jpg";
import gallery88 from "../assets/gallery/image (88).jpg";
import gallery89 from "../assets/gallery/image (89).jpg";
import gallery90 from "../assets/gallery/image (90).jpg";
// import gallery91 from "../assets/gallery/image (91).jpg";
// import gallery92 from "../assets/gallery/image (92).jpg";
// import gallery93 from "../assets/gallery/image (93).jpg";
// import gallery94 from "../assets/gallery/image (94).jpg";
// import gallery95 from "../assets/gallery/image (95).jpg";
// import gallery96 from "../assets/gallery/image (96).jpg";
// import gallery97 from "../assets/gallery/image (97).jpg";
// import gallery98 from "../assets/gallery/image (98).jpg";
// import gallery99 from "../assets/gallery/image (99).jpg";
// import gallery100 from "../assets/gallery/image (100).jpg";
// import gallery101 from "../assets/gallery/image (101).jpg";
// import gallery102 from "../assets/gallery/image (102).jpg";
// import gallery103 from "../assets/gallery/image (103).jpg";
// import gallery104 from "../assets/gallery/image (104).jpg";
// import gallery105 from "../assets/gallery/image (105).jpg";
// import gallery106 from "../assets/gallery/image (106).jpg";
// import gallery107 from "../assets/gallery/image (107).jpg";
// import gallery108 from "../assets/gallery/image (108).jpg";
// import gallery109 from "../assets/gallery/image (109).jpg";
// import gallery110 from "../assets/gallery/image (110).jpg";
// import gallery111 from "../assets/gallery/image (111).jpg";
// import gallery112 from "../assets/gallery/image (112).jpg";
// import gallery113 from "../assets/gallery/image (113).jpg";
// import gallery114 from "../assets/gallery/image (114).jpg";
// import gallery115 from "../assets/gallery/image (115).jpg";
// import gallery116 from "../assets/gallery/image (116).jpg";
// import gallery117 from "../assets/gallery/image (117).jpg";
// import gallery118 from "../assets/gallery/image (118).jpg";
// import gallery119 from "../assets/gallery/image (119).jpg";
// import gallery120 from "../assets/gallery/image (120).jpg";
// import gallery121 from "../assets/gallery/image (121).jpg";
// import gallery122 from "../assets/gallery/image (122).jpg";
// import gallery123 from "../assets/gallery/image (123).jpg";
// import gallery124 from "../assets/gallery/image (124).jpg";
// import gallery125 from "../assets/gallery/image (125).jpg";
// import gallery126 from "../assets/gallery/image (126).jpg";
// import gallery127 from "../assets/gallery/image (127).jpg";
// import gallery128 from "../assets/gallery/image (128).jpg";
// import gallery129 from "../assets/gallery/image (129).jpg";
// import gallery130 from "../assets/gallery/image (130).jpg";
// import gallery131 from "../assets/gallery/image (131).jpg";
// import gallery132 from "../assets/gallery/image (132).jpg";
// import gallery133 from "../assets/gallery/image (133).jpg";
// import gallery134 from "../assets/gallery/image (134).jpg";
// import gallery135 from "../assets/gallery/image (135).jpg";
// import gallery136 from "../assets/gallery/image (136).jpg";
// import gallery137 from "../assets/gallery/image (137).jpg";
// import gallery138 from "../assets/gallery/image (138).jpg";
// import gallery139 from "../assets/gallery/image (139).jpg";
import code1 from "../assets/code/1.jpg";
import code2 from "../assets/code/2.jpg";
import code3 from "../assets/code/3.jpg";
import code4 from "../assets/code/4.jpg";
import code5 from "../assets/code/5.jpg";
import code6 from "../assets/code/6.jpg";
import code7 from "../assets/code/7.jpg";

import champion1 from "../assets/champion/1.jpg";
import champion2 from "../assets/champion/2.jpg";
import champion3 from "../assets/champion/3.jpg";
import champion4 from "../assets/champion/4.jpg";
import champion5 from "../assets/champion/5.jpg";
import champion6 from "../assets/champion/6.jpg";
import champion7 from "../assets/champion/7.jpg";
import champion8 from "../assets/champion/8.jpg";
import champion9 from "../assets/champion/9.jpg";

export const ChampionsImages = [
  champion1,
  champion2,
  champion3,
  champion4,
  champion5,
  champion6,
  champion7,
  champion8,
  champion9,
];

export const Gallery = {
  code1,
  code2,
  code3,
  code4,
  code5,
  code6,
  code7,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  gallery16,
  gallery17,
  gallery18,
  gallery19,
  gallery20,
  gallery21,
  gallery22,
  gallery23,
  gallery24,
  gallery25,
  gallery26,
  gallery27,
  gallery28,
  gallery29,
  gallery30,
  gallery31,
  gallery32,
  gallery33,
  gallery34,
  gallery35,
  gallery36,
  gallery37,
  gallery38,
  gallery39,
  gallery40,
  gallery41,
  gallery42,
  gallery43,
  gallery44,
  gallery45,
  gallery46,
  gallery47,
  gallery48,
  gallery49,
  gallery50,
  gallery51,
  gallery52,
  gallery53,
  gallery54,
  gallery55,
  gallery56,
  gallery57,
  gallery58,
  gallery59,
  gallery60,
  gallery61,
  gallery62,
  gallery63,
  gallery64,
  gallery65,
  gallery66,
  gallery67,
  gallery68,
  gallery69,
  gallery70,
  gallery71,
  gallery72,
  gallery73,
  gallery74,
  gallery75,
  gallery76,
  gallery77,
  gallery78,
  gallery79,
  gallery80,
  gallery81,
  gallery82,
  gallery83,
  gallery84,
  gallery85,
  gallery86,
  gallery87,
  gallery88,
  gallery89,
  gallery90,
};
export const GalleryArray = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  gallery16,
  gallery17,
  gallery18,
  gallery19,
  gallery20,
  gallery21,
  gallery22,
  gallery23,
  gallery24,
  gallery25,
  gallery26,
  gallery27,
  gallery28,
  gallery29,
  gallery30,
  gallery31,
  gallery32,
  gallery33,
  gallery34,
  gallery35,
  gallery36,
  gallery37,
  gallery38,
  gallery39,
  gallery40,
  gallery41,
  gallery42,
  gallery43,
  gallery44,
  gallery45,
  gallery46,
  gallery47,
  gallery48,
  gallery49,
  gallery50,
  gallery51,
  gallery52,
  gallery53,
  gallery54,
  gallery55,
  gallery56,
  gallery57,
  gallery58,
  gallery59,
  gallery60,
  gallery61,
  gallery62,
  gallery63,
  gallery64,
  gallery65,
  gallery66,
  gallery67,
  gallery68,
  gallery69,
  gallery70,
  gallery71,
  gallery72,
  gallery73,
  gallery74,
  gallery75,
  gallery76,
  gallery77,
  gallery78,
  gallery79,
  gallery80,
  gallery81,
  gallery82,
  gallery83,
  gallery84,
  gallery85,
  gallery86,
  gallery87,
  gallery88,
  gallery89,
  gallery90,
];
