import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Images } from "../../helpers/Images";
import { useNavigate } from "react-router-dom";
import CategoriesCarousel from "../../Components/organisms/Home/CategoriesCarousel/CategoriesCarousel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Services.css";

const ServicesTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "50px 20px",
            sm: "50px 60px",
            lg: "50px 120px",
            xl: "150px 120px",
          },
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${Images.services})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "400px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px", sm: "60px" },
            fontWeight: "800",
            marginTop: "5px",
            marginBottom: "20px",
            color: "secondary.main",
          }}
        >
          Our Services
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "16px", sm: "23px" },
            fontWeight: "500",
            marginBottom: "20px",
            color: "secondary.main",
          }}
        >
          Welcome to Code Challenge Services, where we offer a range of services
          designed to support academies, coaches, and teachers in integrating
          coding and technology into their educational programs. Whether you're
          looking to enhance your curriculum, upskill your staff, or inspire
          your students, we're here to help...
        </Typography>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              fontSize: "14px",
              marginTop: "0",
              fontWeight: "600",
              backgroundColor: "secondary.main",
              color: "primary.main",
            }}
            onClick={() => navigate("/contact")}
          >
            Start now
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h3"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "22px", sm: "35px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Have a look at what you offer:
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column" },
            // justifyContent: "space-around",
            margin: "20px auto 0px",
            textAlign: "20px",
          }}
        >
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                variant={"h4"}
                sx={{
                  textAlign: "start",
                  fontSize: { xs: "18px", sm: "28px" },
                  fontWeight: "500",
                  color: "primary.darker",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    display: "inline-block",
                    marginRight: "15px",
                  }}
                >
                  ►
                </span>{" "}
                Code to lead Program 2024
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant={"h5"}
                sx={{
                  textAlign: "start",
                  fontSize: { xs: "17px", sm: "23px" },
                  fontWeight: "500",
                  color: "primary.darker",
                }}
              >
                Description:
              </Typography>
              <Box
                sx={{
                  height: "3px",
                  maxWidth: { xs: "80px", md: "100px" },
                  width: "100%",
                  backgroundColor: "#db2422",
                  margin: "2px 0 0 0",
                }}
              />
              <br />
              Code to Lead is an intensive training program specially designed
              for coaches and teachers. It aims to aid and qualify them to be
              prepared for mentoring and coaching students in Code Challenge
              Championship.
              <Typography
                variant={"h5"}
                sx={{
                  textAlign: "start",
                  fontSize: { xs: "17px", sm: "23px" },
                  fontWeight: "500",
                  color: "primary.darker",
                  marginTop: "15px",
                }}
              >
                Benefits:
              </Typography>
              <Box
                sx={{
                  height: "3px",
                  maxWidth: { xs: "40px", md: "60px" },
                  width: "100%",
                  backgroundColor: "#db2422",
                  margin: "0px",
                }}
              />
              <br />
              <ul
                style={{
                  marginTop: "0px",
                }}
              >
                <li>
                  Gain comprehensive knowledge and skills to effectively mentor
                  and coach students.
                </li>
                <li>
                  Access to resources and materials to enhance coaching
                  abilities.
                </li>
                <li>
                  Networking opportunities with other coaches and teachers in
                  the field.
                </li>
                <li>
                  Receive a certification of completion upon finishing the
                  program.
                </li>
              </ul>
              <Typography
                variant={"h5"}
                sx={{
                  textAlign: "start",
                  fontSize: { xs: "17px", sm: "23px" },
                  fontWeight: "500",
                  color: "primary.darker",
                  marginTop: "15px",
                }}
              >
                Details:
              </Typography>
              <Box
                sx={{
                  height: "3px",
                  maxWidth: { xs: "40px", md: "60px" },
                  width: "100%",
                  backgroundColor: "#db2422",
                  margin: "0px",
                }}
              />
              <br />
              <ul
                style={{
                  marginTop: "0px",
                }}
              >
                <li>
                  The program will be a total of 12 online hours, divided into 4
                  sessions, each lasting 3 hours.
                </li>
                <li>Each group will be from 2 to 5 persons.</li>
                <li>The total cost of the program is 2,400 EGP.</li>
                <li>Training is available for all 4 competition categories.</li>
              </ul>
              <a
                download
                href={
                  "https://drive.google.com/uc?export=download&id=1BNm6AsDxniXlBqVRuqunTMumn2-QeB8E"
                }
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    fontSize: "15px",
                    margin: "5px 0 0",
                    fontWeight: "600",
                    backgroundColor: "#db2422",
                    padding: "3px 10px",
                    marginBottom: "15px",
                  }}
                >
                  Download Code to Lead ( PDF )
                </Button>
              </a>
            </AccordionDetails>
          </Accordion>
          {/* <Typography
            variant={"h4"}
            sx={{
              textAlign: "start",
              fontSize: { xs: "20px", sm: "30px" },
              fontWeight: "500",
              color: "primary.darker",
              display: "flex",
              alignItems: "center",
              margin: "0px 0px 20px",
            }}
          >
            <span
              style={{
                fontSize: "15px",
                display: "inline-block",
                marginRight: "15px",
              }}
            >
              ►
            </span>{" "}
            Code to lead Program 2024
          </Typography>
          <Box sx={{ marginLeft: "25px" }}>
            <Typography
              variant={"h5"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "17px", sm: "25px" },
                fontWeight: "500",
                color: "primary.darker",
              }}
            >
              Description:
            </Typography>
            <Box
              sx={{
                height: "3px",
                maxWidth: { xs: "80px", md: "100px" },
                width: "100%",
                backgroundColor: "#db2422",
                margin: "5px 0 0 0",
              }}
            />
            <br />
            Code to Lead is an intensive training program specially designed for
            coaches and teachers. It aims to aid and qualify them to be prepared
            for mentoring and coaching students in Code Challenge Championship.
            <Typography
              variant={"h5"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "17px", sm: "25px" },
                fontWeight: "500",
                color: "primary.darker",
                marginTop: "15px",
              }}
            >
              Benefits:
            </Typography>
            <Box
              sx={{
                height: "3px",
                maxWidth: { xs: "80px", md: "100px" },
                width: "100%",
                backgroundColor: "#db2422",
                margin: "5px 0 0 0",
              }}
            />
            <br />
            <ul
              style={{
                marginTop: "0px",
              }}
            >
              <li>
                Gain comprehensive knowledge and skills to effectively mentor
                and coach students.
              </li>
              <li>
                Access to resources and materials to enhance coaching abilities.
              </li>
              <li>
                Networking opportunities with other coaches and teachers in the
                field.
              </li>
            </ul>
            <Typography
              variant={"h5"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "17px", sm: "25px" },
                fontWeight: "500",
                color: "primary.darker",
                marginTop: "15px",
              }}
            >
              Details:
            </Typography>
            <Box
              sx={{
                height: "3px",
                maxWidth: { xs: "80px", md: "100px" },
                width: "100%",
                backgroundColor: "#db2422",
                margin: "5px 0 0 0",
              }}
            />
            <br />
            <ul
              style={{
                marginTop: "0px",
              }}
            >
              <li>The program will be a total of 12 hours online in groups.</li>
              <li>Each group will be from 2 to 5 persons.</li>
              <li>The total cost of the program is 2,400 EGP.</li>
              <li>Training is available for all 4 competition categories.</li>
            </ul>
          </Box> */}
        </Box>
      </Box>
      <CategoriesCarousel />
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px 0", sm: "50px 60px 0", lg: "50px 120px 0" },
          maxWidth: "1500px",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "20px", sm: "30px" },
            fontWeight: "500",
            color: "primary.darker",
            padding: "0 10px",
          }}
        >
          Contact us to learn more and join the movement towards a more
          innovative and engaging approach to education.
        </Typography>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
            marginBottom: "40px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ minWidth: "200px" }}
            onClick={() => navigate("/contact")}
          >
            Join Us Today!
          </Button>
        </div>
        <img
          src={Images.cup}
          style={{ width: "100%", maxWidth: "1000px", margin: "auto" }}
        />
      </Box>
    </>
  );
};

export default ServicesTemplate;
