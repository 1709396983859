import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Images } from "../../../../helpers/Images";
import { categoriesData } from "../../../../helpers/Arrays";
import { Gallery, GalleryArray } from "../../../../helpers/gallery";

const PythonTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        {false ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "40px", sm: "70px" },
                fontWeight: "500",
                color: "primary.darker",
                margin: "100px auto 0 auto",
              }}
            >
              COMING SOON
            </Typography>
            <Box
              sx={{
                height: "3px",
                maxWidth: { xs: "100px", md: "300px" },
                width: "100%",
                backgroundColor: "primary.main",
                margin: "5px auto 5px auto",
              }}
            />
            <Typography
              variant={"h2"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "40px", sm: "40px" },
                fontWeight: "500",
                color: "primary.darker",
                margin: "5px auto 100px auto",
              }}
            >
              Code Challenge
            </Typography>
          </Box>
        ) : (
          <Grid
            container
            spacing={4}
            alignItems={"start"}
            justifyContent={"space-between"}
          >
            <Grid item xs={12}>
              <Typography
                variant={"h1"}
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "40px", sm: "55px" },
                  fontWeight: "500",
                  color: "#010101",
                }}
              >
                Python Challenge
              </Typography>
              <Box
                sx={{
                  height: "3px",
                  maxWidth: { xs: "100px", md: "150px" },
                  width: "100%",
                  backgroundColor: "#db2422",
                  margin: "10px auto",
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1fJNm6vlMX8SpXNVQWo3RZ5P2m-DJx-PH"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "17px",
                      margin: "5px",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                    }}
                  >
                    Download General Rules (PDF)
                  </Button>
                </a>
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=14hT_gv_us7-qPTALEtmqZB3nGbdHTgsm"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "17px",
                      margin: "5px",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                    }}
                  >
                    Download Python Rules (PDF)
                  </Button>
                </a>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1damgx7HrrnaLjaJccT9S7PCOQ9vpPnSK"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "17px",
                      margin: "5px 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                    }}
                  >
                    Download Python Challenge 2024 Scoring Sheet
                  </Button>
                </a>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    fontSize: "17px",
                    margin: "5px 0",
                    fontWeight: "600",
                    backgroundColor: "#db2422",
                  }}
                  onClick={() => navigate("/firststage")}
                >
                  Register Now
                </Button>
              </Box>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "primary.darker",
                  // maxWidth: "750px",
                  margin: "25px 0 10px 0",
                  fontSize: "17px",
                }}
              >
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "30px" },
                    fontWeight: "500",
                    color: "#010101",
                    marginTop: "50px",
                    marginBottom: "10px",
                  }}
                >
                  Brief:
                </Typography>
                Python is one of the easiest programming languages as it is
                quite close to our natural language. It’s widely used all over
                the world and considered to be the most popular language due to
                its huge community as it’s well recognized in all problemsolving
                competitions, and used in automation, AI, Data Science, creating
                websites and games, and many more. So, if you want to solve
                simulated real-world problems, and raise your problemsolving
                skills, join us this year in Python Challenge!
                <br />
                <br />
                In Python challenge, there are{" "}
                <strong>three main categories and three main rounds</strong> in
                each category with different challenges. Each participant/team
                should finish the challenge and submit their work in specified
                time !..
                <br />
                <br />
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#010101",
                    marginTop: "20px",
                  }}
                >
                  Junior Category:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "#db2422",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Age:
                </span>{" "}
                10 - 13
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Team size:
                </span>{" "}
                1 - 3 people guided by a coach <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Software:
                </span>{" "}
                Python (VS code, PyCharm, and Jupyter notebook)
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/1a1ZwkgJHiuwmrq2XP-qhRl2ku76MCJCF?usp=sharing"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Python Junior 2023 Mission 1
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/1tGekVJEntAjWJZA4ct9YgIz1KWWZdOPs?usp=sharing"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Python Junior 2023 Mission 2
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/17PV8GVav2p-oclo6c-5qUEnEYT1TYQF2?usp=sharing"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Python Junior 2023 Mission 3
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1dVy71ZBpDzrsjJ71VQQHDOKwSyAyk60L"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Download Python Junior 2023 samples
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/1HXYGgRKLppbTUIGYf5O7atoxgaBBUID6?usp=drive_link"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Python 2023 samples answers
                  </Button>
                </a>
                <br />
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#010101",
                    marginTop: "40px",
                  }}
                >
                  Senior Category:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "#db2422",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Age:
                </span>{" "}
                14 - 17
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Team size:
                </span>{" "}
                1 - 3 people guided by a coach
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Software:
                </span>{" "}
                Python (VS code, PyCharm, and Jupyter notebook)
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/1AebNT1dS3S-GjWW5Cmi_69nJHG1Hpqix?usp=sharing"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Python Senior 2023 Mission 1
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/1DA_RamSgDmydY5-8yZ0Z9R8RSXEVnigd?usp=sharing"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Python Senior 2023 Mission 2
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/1w2KMszzANppqYD6tMnIFOn8BWPQvDJd5?usp=sharing"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Python Senior 2023 Mission 3
                  </Button>
                </a>
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1QthNWpFEq5AHn0UdcbH0r7PELZjBzha9"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Download Python Senior 2023 samples
                  </Button>
                </a>
                <br />
                <a
                  target="_blank"
                  href={
                    "https://drive.google.com/drive/folders/1dmEmSQuIVU-OzoDBg3ZXqL9WLKa2RIeW"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Python 2023 Senior answers
                  </Button>
                </a>
                <br />
                <Typography
                  variant={"h1"}
                  sx={{
                    textAlign: "start",
                    fontSize: { xs: "28px" },
                    fontWeight: "500",
                    color: "#010101",
                    marginTop: "40px",
                  }}
                >
                  Adult Category:
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: { xs: "100px", md: "150px" },
                    width: "100%",
                    backgroundColor: "#db2422",
                    margin: "10px 0 0 0",
                  }}
                />
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Age:
                </span>{" "}
                18 - 24
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Team size:
                </span>{" "}
                1 - 3 people guided by a coach
                <br />
                <span style={{ fontWeight: "700", color: "#db2422" }}>
                  Software:
                </span>{" "}
                Python (VS code, PyCharm, and Jupyter notebook)
                <br />
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1f8vPKJ94LSU31eYn2XkzkgZU3rKFGzPa"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      margin: "5px 0 0",
                      fontWeight: "600",
                      backgroundColor: "#db2422",
                      padding: "3px 10px",
                    }}
                  >
                    Download Python Adult 2024 sample
                  </Button>
                </a>
                <br />
              </Typography>
              <br />
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              sx={{
                marginTop: "20px",
                justifyContent: "center",
                marginLeft: "16px",
                marginBottom: "20px",
              }}
            >
              {GalleryArray?.sort((a, b) => 0.5 - Math.random())
                ?.slice(0, 7)
                ?.map((item, i) => (
                  <Grid item xs={12} sm={6} md={3} key={i}>
                    <img
                      loading={"lazy"}
                      alt={i}
                      src={item}
                      style={{ width: "100%", borderRadius: "10px" }}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "primary.main",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
            maxWidth: "1500px",
            margin: "auto",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              textAlign: "center",
              fontSize: { xs: "40px" },
              fontWeight: "500",
              color: "secondary.main",
            }}
          >
            Our All Categories
          </Typography>
          <Box
            sx={{
              height: "3px",
              maxWidth: { xs: "100px", md: "150px" },
              width: "100%",
              backgroundColor: "secondary.main",
              margin: "10px auto 0 auto",
            }}
          />
          <Grid
            container
            spacing={2}
            sx={{ marginTop: "50px", justifyContent: "center" }}
          >
            {categoriesData
              ?.filter((category) => category.title !== "Python Challenge")
              ?.map((item) => (
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${item.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      width: "100%",
                      minHeight: { xs: "200px", sm: "250px" },
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      variant={"h3"}
                      sx={{
                        textAlign: "center",
                        fontSize: "35px",
                        fontWeight: "500",
                        color: "secondary.main",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Box
                      sx={{
                        height: "3px",
                        maxWidth: "90px",
                        width: "100%",
                        backgroundColor: "secondary.main",
                        margin: "5px auto 5px auto",
                      }}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "primary.main",
                          width: "fit-content",
                          margin: { xs: "5px 0 20px 0", sm: "10px 0 40px 0" },
                        }}
                        onClick={() => navigate(item.link)}
                      >
                        view
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default PythonTemplate;
