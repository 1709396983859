import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { academiesData } from "../../helpers/Arrays";
import LogoCarouse from "../../Components/organisms/Home/LogoCarousel/LogoCarousel";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import { Images } from "../../helpers/Images";
import { useNavigate } from "react-router-dom";
import MilitaryTechRoundedIcon from "@mui/icons-material/MilitaryTechRounded";
import CardMembershipRoundedIcon from "@mui/icons-material/CardMembershipRounded";
import ShoppingBagRoundedIcon from "@mui/icons-material/ShoppingBagRounded";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import CategoriesCarousel from "../../Components/organisms/Home/CategoriesCarousel/CategoriesCarousel";
import "./Prizes.css";

const PrizesTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "50px 20px",
            sm: "50px 60px",
            lg: "50px 120px",
            xl: "150px 120px",
          },
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${Images.gold})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "400px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "20px", sm: "30px" },
            fontWeight: "500",
            color: "secondary.main",
          }}
        >
          This year, we're proud to announce a total prizes and gifts pool of
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "50px", sm: "80px" },
            fontWeight: "900",
            marginTop: "5px",
            marginBottom: "20px",
            color: "secondary.main",
          }}
        >
          200,000 EGP
        </Typography>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              fontSize: "14px",
              marginTop: "0",
              fontWeight: "600",
              backgroundColor: "secondary.main",
              color: "primary.main",
            }}
            onClick={() => navigate("/firststage")}
          >
            Register Now
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "20px", sm: "35px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Have a look at what you could win:
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            margin: "20px auto 0px",
            maxWidth: "800px",
            textAlign: "20px",
          }}
        >
          <Grid container>
            <Grid item xs={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "5px",
                  marginBottom: "20px",
                }}
              >
                {/* <MilitaryTechRoundedIcon sx={{ fontSize: "120px" }} /> */}
                <img src={Images.prize4} className="prizeImg" />
                <Typography
                  variant={"h3"}
                  sx={{
                    textAlign: "center",
                    fontSize: { xs: "17px", sm: "25px" },
                    fontWeight: "500",
                    color: "primary.darker",
                    marginTop: "10px",
                  }}
                >
                  Medals
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "5px",
                  marginBottom: "20px",
                }}
              >
                {/* <EmojiEventsRoundedIcon sx={{ fontSize: "120px" }} /> */}
                <img src={Images.prize2} className="prizeImg" />
                <Typography
                  variant={"h3"}
                  sx={{
                    textAlign: "center",
                    fontSize: { xs: "17px", sm: "25px" },
                    fontWeight: "500",
                    color: "primary.darker",
                    marginTop: "10px",
                  }}
                >
                  Trophies
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "5px",
                  marginBottom: "20px",
                }}
              >
                {/* <LocalAtmIcon sx={{ fontSize: "120px" }} /> */}
                <img src={Images.prize5} className="prizeImg" />
                <Typography
                  variant={"h3"}
                  sx={{
                    textAlign: "center",
                    fontSize: { xs: "17px", sm: "25px" },
                    fontWeight: "500",
                    color: "primary.darker",
                    marginTop: "10px",
                  }}
                >
                  Cash Prizes
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "5px",
                  marginBottom: "20px",
                }}
              >
                {/* <ShoppingBagRoundedIcon sx={{ fontSize: "120px" }} /> */}
                <img src={Images.prize3} className="prizeImg" />
                <Typography
                  variant={"h3"}
                  sx={{
                    textAlign: "center",
                    fontSize: { xs: "17px", sm: "25px" },
                    fontWeight: "500",
                    color: "primary.darker",
                    marginTop: "10px",
                  }}
                >
                  Welcome Bags
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "5px",
                  marginBottom: "20px",
                }}
              >
                {/* <CardMembershipRoundedIcon sx={{ fontSize: "120px" }} /> */}
                <img src={Images.prize1} className="prizeImg" />
                <Typography
                  variant={"h3"}
                  sx={{
                    textAlign: "center",
                    fontSize: { xs: "17px", sm: "25px" },
                    fontWeight: "500",
                    color: "primary.darker",
                    marginTop: "10px",
                  }}
                >
                  Accredited Certificates
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "5px",
                  marginBottom: "20px",
                }}
              >
                {/* <CardGiftcardIcon sx={{ fontSize: "120px" }} /> */}
                <img src={Images.prize6} className="prizeImg" />
                <Typography
                  variant={"h3"}
                  sx={{
                    textAlign: "center",
                    fontSize: { xs: "17px", sm: "25px" },
                    fontWeight: "500",
                    color: "primary.darker",
                    marginTop: "10px",
                  }}
                >
                  Gift Vouchers
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            margin: "20px auto 0px",
            maxWidth: "800px",
            textAlign: "20px",
          }}
        >
          <ul className="WhyList">
            <li
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <CardMembershipRoundedIcon />
              <span style={{ padding: "0 10px" }}>
                Accredited Certificates: Official recognition of your
                participation certified by STEM.org
              </span>
              <CardMembershipRoundedIcon />
            </li>
            <li
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <ShoppingBagRoundedIcon />
              <span style={{ padding: "0 10px" }}>
                Welcome Bags: Exciting gifts exclusively brought by Code
                Challenge
              </span>
              <ShoppingBagRoundedIcon />
            </li>
            <li
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <CardGiftcardIcon />
              <span style={{ padding: "0 10px" }}>
                Gift Vouchers: Unique gift vouchers provided by the top tech
                stores
              </span>
              <CardGiftcardIcon />
            </li>
            <li
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <LocalAtmIcon />
              <span style={{ padding: "0 10px" }}>
                Cash Prizes: Hard cash as a reward for your coding brilliance
              </span>
              <LocalAtmIcon />
            </li>
            <li
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <EmojiEventsRoundedIcon />
              <span style={{ padding: "0 10px" }}>
                Trophies: Display your victory proudly with our stunning
                championship trophies
              </span>
              <EmojiEventsRoundedIcon />
            </li>
            <li
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <MilitaryTechRoundedIcon />
              <span style={{ padding: "0 10px" }}>
                Medals: Achievements will be around your neck with our
                prestigious medals
              </span>
              <MilitaryTechRoundedIcon />
            </li>
          </ul>
        </Box> */}
      </Box>
      <CategoriesCarousel />
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px 0", sm: "50px 60px 0", lg: "50px 120px 0" },
          maxWidth: "1500px",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "20px", sm: "30px" },
            fontWeight: "500",
            color: "primary.darker",
            padding: "0 10px",
          }}
        >
          Join us this year to be one of the winners with those exciting prizes!
        </Typography>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
            marginBottom: "40px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ minWidth: "200px" }}
            onClick={() => navigate("/firststage")}
          >
            Register Now
          </Button>
        </div>
        <img
          src={Images.cup}
          style={{ width: "100%", maxWidth: "1000px", margin: "auto" }}
        />
      </Box>
    </>
  );
};

export default PrizesTemplate;
